import * as _ from 'lodash';
import authService from '../../api/auth';
import settingsApi from '../../api/settings';
import config from '../../config';
import router from '../../router/router';
import { usePaymentsStore } from '../../stores/payments';
import { getRootWindow } from '../../utils/sso';
import * as types from '../mutation_types';
const cookieMonitorLogoutCallback = commit => () => {
    commit(types.AUTH_COOKIES_EXPIRED, true);
};
const cookieMonitorLoginCallback = (commit, state) => newUser => {
    if (!state.userId || _.get(newUser, 'userId', null) === state.userId) {
        if (state.cookiesExpired) {
            commit(types.AUTH_COOKIES_EXPIRED, false);
        }
        if (state.cookiesAnotherUser) {
            commit(types.AUTH_COOKIES_EXPIRED, true);
        }
    }
    else {
        if (!state.cookiesAnotherUser) {
            commit(types.AUTH_ANOTHER_USER);
        }
    }
};
// initial state
const state = {
    get token() {
        return authService.getUserToken();
    },
    accountId: null,
    username: null,
    userId: null,
    error: null,
    authorized: null,
    multiUserId: null,
    role: null,
    initialPath: null,
    redirectPath: null,
    allowGuest: false,
    twoFactorCheck: false,
    identityProvider: null,
    multiUserIsLoggingUser: false,
    multiUser: null,
    availableUsers: [],
    cookiesExpired: false,
    cookiesAnotherUser: false,
    cookiesNoRights: false,
    sso_needs_two_factor: false,
    isSSO: false,
    sso_key: null,
    permissions: null,
    isReadonly: false,
    isAdmin: true,
    isPayable: false,
    isSuspended: false,
};
// mutations
const mutations = {
    [types.AUTH_COOKIES_EXPIRED](authState, value) {
        authState.cookiesExpired = value;
        authState.cookiesAnotherUser = false;
        authState.cookiesNoRights = false;
    },
    [types.AUTH_ANOTHER_USER](authState) {
        authState.cookiesExpired = false;
        authState.cookiesAnotherUser = true;
        authState.cookiesNoRights = false;
    },
    [types.AUTH_NO_RIGHTS](authState) {
        authState.cookiesExpired = false;
        authState.cookiesAnotherUser = false;
        authState.cookiesNoRights = true;
    },
    [types.AUTH_INIT](authState, initState) {
        var _a, _b;
        authState.accountId = _.get(initState, 'accountId', null);
        authState.multiUserId = _.get(initState, 'multiUserId', null);
        authState.username = _.get(initState, 'username', null);
        authState.userId = _.get(initState, 'userId', null);
        authState.role = _.get(initState, 'role', null);
        authState.authorized = _.get(initState, 'authorized', null);
        authState.error = _.get(initState, 'error', null);
        authState.initialPath = _.get(initState, 'initialPath');
        authState.identityProvider = _.get(initState, 'identityProvider', null);
        authState.twoFactorEnabled = _.get(initState, 'twoFactorEnabled', false);
        authState.permissions = _.get(initState, 'permissions', null);
        authState.isReadonly = authState.role === 'USER' && ((_b = (_a = authState === null || authState === void 0 ? void 0 : authState.permissions) === null || _a === void 0 ? void 0 : _a.scope) === null || _b === void 0 ? void 0 : _b.includes('READONLY'));
        authState.isAdmin = authState.role === 'ADMIN' || authState.role === 'SUPER_ADMIN';
    },
    [types.SET_IS_PAYABLE](authState, isPayable) {
        authState.isPayable = isPayable;
    },
    [types.AUTH_SUCCESS](authState, authData) {
        authState.accountId = authData.accountId;
        authState.username = authData.username;
        authState.userId = authData.userId;
        authState.role = authData.role;
        authState.multiUserId = authData.multiUserId;
        authState.identityProvider = authData.identityProvider;
        authState.twoFactorEnabled = authData.twoFactorEnabled;
        authState.authorized = true;
        authState.twoFactorCheck = false;
        authState.error = null;
        authState.sso_key = null;
    },
    [types.AUTH_ERROR](authState, error) {
        authState.accountId = null;
        authState.username = null;
        authState.userId = null;
        authState.role = null;
        authState.identityProvider = null;
        authState.authorized = false;
        authState.error = error;
        authState.multiUserId = null;
    },
    [types.SIGN_OUT](authState) {
        authState.accountId = null;
        authState.username = null;
        authState.userId = null;
        authState.role = null;
        authState.authorized = false;
        authState.error = null;
        authState.identityProvider = null;
        authState.twoFactorCheck = false;
        authState.initialPath = null;
        authState.multiUserId = null;
        authState.sso_needs_two_factor = false;
        authState.multiUser = null;
        authState.sso_key = null;
        authState.isPayable = false;
        authState.isSuspended = false;
    },
    [types.TWO_FACTOR_CHECK](authState, value) {
        authState.twoFactorCheck = value;
    },
    [types.SET_REDIRECT_URL](authState, value) {
        authState.redirectPath = value;
    },
    [types.MULTI_USER_IS_LOGGIN_USER](authState, value) {
        authState.multiUserIsLoggingUser = value;
    },
    [types.AUTH_SET_MULTI_USER](authState, value) {
        authState.multiUser = value;
    },
    [types.MULTI_USER_TOGGLE_MFA](authState) {
        authState.multiUser.twoFactorCheck = !authState.multiUser.twoFactorCheck;
    },
    [types.MULTI_USER_AVAILABLE_USERS](authState, users) {
        authState.availableUsers = users;
    },
    [types.SET_IS_SSO](authState) {
        authState.isSSO = true;
    },
    [types.SSO_NEEDS_TWO_FACTOR](authState, { error, KEY }) {
        authState.twoFactorCheck = true;
        authState.error = error;
        authState.sso_needs_two_factor = true;
        if (KEY)
            authState.sso_key = KEY;
    },
    [types.AUTH_SUSPENDED](authState, { suspended }) {
        authState.isSuspended = suspended;
    },
};
const actions = {
    init({ commit, state }, { initialPath, clearCookies = true }) {
        // if initial path starts with '/login' then replace it with '/'
        initialPath = initialPath.match(/^\/login/) ? '/' : initialPath;
        const callback = (user) => {
            authService.monitorCookie(cookieMonitorLogoutCallback(commit), cookieMonitorLoginCallback(commit, state));
            commit(types.AUTH_INIT, _.assign({}, user, {
                authorized: true,
                initialPath,
            }));
            authService.validateMultiUserToken().catch(console.warn);
        };
        return authService.validateUser(true)
            .then(user => callback(user))
            .catch(async (error) => {
            var _a, _b, _c;
            if ((_c = (_b = (_a = error.cause) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message.includes('account is suspended')) {
                commit(types.AUTH_SUSPENDED, { suspended: true });
                // retry user valitaion with suspended route
                const userOrError = await authService.validateUser(true, false, true).catch(error => error);
                if (!(userOrError instanceof Error)) {
                    callback(userOrError);
                    return userOrError;
                }
                else
                    error = userOrError;
            }
            if (clearCookies)
                authService.signOut();
            authService.monitorCookie(_.noop, loggedInUser => {
                commit(types.AUTH_INIT, _.assign({}, loggedInUser, {
                    authorized: true,
                    initialPath,
                }));
            });
            commit(types.AUTH_INIT, {
                authorized: false,
                initialPath,
            });
            if (error.errorType === 'NoRightsError') {
                commit(types.AUTH_NO_RIGHTS);
            }
            throw error;
        });
    },
    async refreshSuspendedUserToken({ commit }) {
        await authService.refreshUserToken();
        return authService.validateUser(true).then(user => {
            commit(types.AUTH_SUSPENDED, { suspended: false });
            commit(types.AUTH_INIT, _.assign({}, user, {
                authorized: true,
            }));
            authService.validateMultiUserToken().catch(console.warn);
        }).catch(error => console.warn(error));
    },
    // eslint-disable-next-line
    async signIn({ commit, state, dispatch, }, { credentials, keepLoggedIn, captchaToken, isCaptchaV2Checkbox, verificationCode, rememberTwoFactor, accountData, }) {
        return authService.signIn({
            long: keepLoggedIn,
            allowGuestLogin: state.allowGuest,
            credentials,
            captchaToken,
            isCaptchaV2Checkbox,
            verificationCode,
            rememberTwoFactor,
        })
            .then(async (result) => {
            if (result.twoFactorCheck) {
                await commit(types.TWO_FACTOR_CHECK, true);
            }
            else {
                if (result.tokenType === 'multi-user') {
                    await dispatch('validateMultiUser');
                    return await dispatch('authorizeMult', {
                        keepLoggedIn,
                        accountData,
                    });
                }
                commit(types.AUTH_SUCCESS, result);
            }
        })
            .catch(error => {
            commit(types.AUTH_ERROR, error);
        });
    },
    async authorizeMult({ state, dispatch, commit }, { keepLoggedIn, accountData, source = 'user-action' }) {
        sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem('keepLoggedIn', keepLoggedIn);
        await dispatch('loadUsers');
        const previousMultRoute = sessionStorage.getItem('redirect-mult') || '';
        if (/^\/or-auth/.test(previousMultRoute)) {
            sessionStorage.removeItem('redirect-mult');
            return router.push(previousMultRoute);
        }
        let accountUser, foundUserFromSSO;
        if (!_.isEmpty(accountData)) {
            accountUser = _.find(state.availableUsers, user => {
                return _.keys(accountData).some(prop => user[prop] === accountData[prop]);
            });
            foundUserFromSSO = !!accountUser;
        }
        if (state.availableUsers.length === 1) {
            accountUser = state.availableUsers[0];
        }
        if (accountUser) {
            // log user in; get token
            try {
                await dispatch('multiUserLoginUser', {
                    ...accountUser,
                    long: keepLoggedIn,
                });
            }
            catch (e) {
                return router.push('/multi-user/list-users');
            }
            // accessing account
            try {
                if (state.isSSO) {
                    let rootWindow;
                    if (window.opener) {
                        rootWindow = window.opener;
                    }
                    else if (window.self !== window.top) { // Check if current window is an iframe
                        rootWindow = window.parent;
                    }
                    if (rootWindow) {
                        return rootWindow.postMessage({
                            type: 'LOGIN_SUCCESS',
                            token: state.token,
                            accountId: state.accountId,
                            source,
                        }, '*');
                    }
                }
                else {
                    let settingRedirectUrl = await settingsApi.getMergedSettings({ key: 'loginRedirectUrl' }).catch(error => {
                        var _a, _b, _c;
                        if ((_c = (_b = (_a = error.cause) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message.includes('suspended')) {
                            return `${config.AUTH_UI_URL}/account/payments`;
                        }
                    });
                    const defaultRedirectUrl = `${config.REPORTING_URL}/dashboard`;
                    settingRedirectUrl = settingRedirectUrl ? settingRedirectUrl : defaultRedirectUrl;
                    const redirectPath = state.redirectPath ? state.redirectPath : settingRedirectUrl;
                    return window.location = redirectPath;
                }
            }
            catch (error) {
                commit(types.AUTH_ERROR, error);
                throw error;
            }
        }
        else if (state.isSSO && !foundUserFromSSO) {
            const rootWindow = getRootWindow();
            if (rootWindow) {
                return rootWindow.postMessage({
                    type: 'ACCOUNT_NOT_FOUND',
                    availableUsers: JSON.parse(JSON.stringify(state.availableUsers)),
                    username: _.get(state.multiUser, 'username'),
                    source,
                }, '*');
            }
        }
        return router.push('/multi-user/list-users');
    },
    async recoverSession({ commit }, { credentials, keepLoggedIn, verificationCode, userToken, rememberTwoFactor, userId, accountId, captchaToken, }) {
        try {
            let result = await authService.signIn({
                credentials,
                long: keepLoggedIn,
                verificationCode,
                userToken,
                rememberTwoFactor,
                captchaToken,
            });
            if (result.tokenType === 'multi-user' && !result.twoFactorCheck) {
                result = await authService.multiUserLoginUser({
                    accountId: accountId,
                    id: userId,
                    long: keepLoggedIn,
                });
            }
            if (result.twoFactorCheck) {
                commit(types.TWO_FACTOR_CHECK, true);
            }
            else {
                commit(types.AUTH_SUCCESS, result);
                // authService.monitorCookie(cookieMonitorLogoutCallback, cookieMonitorLoginCallback);
            }
            return result;
        }
        catch (error) {
            commit(types.AUTH_ERROR, error);
        }
    },
    cancelTwoFactorCheck({ commit }) {
        commit(types.TWO_FACTOR_CHECK, false);
    },
    async signInWithMultToken({ dispatch }, { user, keepLoggedIn }) {
        await authService.saveMultiUser(user, keepLoggedIn);
        return dispatch('authorizeMult', { keepLoggedIn });
    },
    // eslint-disable-next-line
    signInWithToken({ commit, state }, { token, allowGuestLogin, userInfo }) {
        return authService.signInWithToken({
            token,
            allowGuestLogin,
        })
            .then(result => {
            commit(types.AUTH_SUCCESS, result);
        })
            .catch(error => {
            console.error('ERROR', error);
            commit(types.AUTH_ERROR, error);
        });
    },
    async loadUsers({ commit }) {
        const availableUsers = await authService.multiUserGetUsersList();
        commit(types.MULTI_USER_AVAILABLE_USERS, availableUsers);
    },
    sucessAuth({ commit }, user) {
        commit(types.AUTH_INIT, _.assign({}, user, { authorized: true }));
    },
    setRedirectUrl({ commit }, redirectPath) {
        const currentHostname = window.location.hostname.split('.');
        const validUrl = new URL(redirectPath).hostname.split('.');
        const currentDomain = currentHostname.slice(-2).join('.');
        const validDomain = validUrl.slice(-2).join('.');
        if (currentDomain === validDomain) {
            commit(types.SET_REDIRECT_URL, redirectPath);
        }
        else {
            window.location.href = '/login';
        }
    },
    async signOut({ commit }, token) {
        await authService.signOut(token);
        authService.stopCookieMonitor();
        commit(types.SIGN_OUT);
    },
    // eslint-disable-next-line no-shadow
    async multiUserLoginUser({ commit, state }, selected) {
        commit(types.MULTI_USER_IS_LOGGIN_USER, true);
        try {
            const user = await authService.multiUserLoginUser(selected);
            commit(types.AUTH_INIT, _.assign({}, user, {
                authorized: true,
                initialPath: state.initialPath,
            }));
        }
        catch (e) {
            console.warn(e);
            throw e;
        }
        finally {
            commit(types.MULTI_USER_IS_LOGGIN_USER, false);
        }
    },
    async validateMultiUser({ commit, state }) {
        if (state.multiUser)
            return;
        const multiUser = await authService.validateMultiUserToken();
        commit(types.AUTH_SET_MULTI_USER, multiUser);
    },
    toggleMultiUserMFA({ commit }) {
        commit(types.MULTI_USER_TOGGLE_MFA);
    },
    setSSOTwoFactor({ commit }, { error, KEY }) {
        commit(types.SSO_NEEDS_TWO_FACTOR, {
            error,
            KEY,
        });
    },
    setIsSSO({ commit }) {
        commit(types.SET_IS_SSO);
    },
    async ssoLoginMFA({ state }, verificationCode) {
        const user = await fetch(`${config.GLOBAL_SSO_URL}/mfa`, {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({
                GATEWAY_URL: config.GATEWAY_URL,
                PROVIDERS_ACCOUNT_ID: config.PROVIDERS_ACCOUNT_ID,
                MFACode: verificationCode,
                KEY: state.sso_key,
            }),
        }).then(r => r.json());
        window.postMessage(user.token ? {
            type: 'SSO_SUCCESS_LOGIN',
            user,
        } : {
            type: 'SSO_TWO_FACTOR_CHECK',
            error: user.error,
            KEY: user.KEY,
        }, '*');
    },
    async getAccountIsPayable({ commit }) {
        if (config.ENABLE_PAYMENTS) {
            const paymentsStore = usePaymentsStore();
            const _isPayable = await paymentsStore.getPayableStatus().then((response) => response.billable);
            commit(types.SET_IS_PAYABLE, _isPayable);
        }
    },
};
const module = {
    namespaced: true,
    actions,
    state,
    mutations,
};
export default module;
